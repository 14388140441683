<script setup lang="ts">
import { tv, type VariantProps } from 'tailwind-variants'

const props = withDefaults(defineProps<Props>(), {
  showButton: true,
})

const isOpen = defineModel<boolean>()

interface Props {
  modelValue?: boolean
  variant?: DialogVariants['variant']
  showButton?: boolean
  class?: any
}

type DialogVariants = VariantProps<typeof dialogVariants>
const dialogVariants = tv({
  base: 'relative max-w-full bg-white p-4 text-base shadow-xlarge md:p-6',
  variants: {
    variant: {
      md: 'mx-auto my-4 w-[calc(100%-32px)] max-w-[578px] rounded-lg',
      full: 'size-full p-6',
    },
  },
  defaultVariants: {
    variant: 'md',
  },
})

const dialogRef = ref<HTMLDialogElement | null>(null)

function showModal() {
  dialogRef?.value?.showModal()

  window.addEventListener('keydown', handleEsc)
}

function close() {
  isOpen.value = false
  dialogRef?.value?.close()
  window.removeEventListener('keydown', handleEsc)
}

function clickOutside(e: MouseEvent) {
  if (e.currentTarget === e.target)
    close()
}

function handleEsc(e: KeyboardEvent) {
  if (e.key === 'Escape')
    isOpen.value = false
}

watch(
  () => props.modelValue,
  () => (props.modelValue ? showModal() : close()),
)

onUnmounted(() => {
  window.removeEventListener('keydown', handleEsc)
})

defineExpose({
  dialogRef,
  isOpen,
  close,
  showModal,
})
</script>

<template>
  <Teleport to="#teleports">
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions vuejs-accessibility/click-events-have-key-events -->
    <dialog
      ref="dialogRef"
      class="cs-dialog min-h-full min-w-full grid-cols-1 items-center justify-center bg-transparent backdrop:backdrop-blur-[3px] [&[open]]:grid"
      @click="clickOutside"
    >
      <div :class="dialogVariants({ variant, class: props.class })">
        <button
          v-if="props.showButton"
          title="Close"
          aria-label="Close"
          class="absolute right-2 top-2 z-1 flex rounded p-2 md:right-4 md:top-4"
          @click="close()"
        >
          <Icon name="heroicons:x-mark" class="size-6" />
        </button>
        <slot />
      </div>
    </dialog>
  </Teleport>
</template>

<style scoped>
/* Transition styles */
.cs-dialog {
  opacity: 0;
  transition-timing-function: ease-out;
  transition-duration: 0.15s;
  transition-property: display opacity overlay;
  transition-behavior: allow-discrete;
  transform: scale(0.96);

  &::backdrop {
    background: rgb(0 0 0 / 50%);
    opacity: 0;
    transition-timing-function: ease-out;
    transition-duration: 0.15s;
    transition-property: display opacity overlay;
    transition-behavior: allow-discrete;
  }

  &[open] {
    opacity: 1;
    transform: scale(1);

    @starting-style {
      opacity: 0;
      transform: scale(0.96);
    }

    &::backdrop {
      opacity: 1;

      @starting-style {
        opacity: 0;
      }
    }
  }
}
</style>
